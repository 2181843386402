//Main Drawer Styling

main {
  margin-top: 63px;
  position: absolute;
  right: 353px;
  left: 0;
}

.select-div {
  display: flex;
  align-items: center;
  .select-div-text {
    color: rgba(21, 50, 95, 0.87);
    font-family: Open Sans;
    font-size: 12px;
  }
  .icon {
    margin-right: 10px;
    margin-left: 5px;
    svg.MuiSvgIcon-root {
      margin-top: 5px;
    }
  }
}
svg.MuiSvgIcon-root.drawer-search {
  fill: #15325f;
}
svg.MuiSvgIcon-root.search-arrow {
  fill: #15325f;
}
.MuiPaper-elevation1 {
  box-shadow: 0px 2px 8px rgba(153, 153, 153, 0.15) !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.search-button {
  height: 36px;
  background: #377ae1;
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.24),
    0px 0px 2px rgba(0, 0, 0, 0.12);
  color: #fff;
  margin-left: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  padding-right: 10px;
}

.top-div {
  display: flex;
  align-items: center;
}

.bread-crum-container {
  padding: 5px;
  margin: 10px 0;
  border-radius: 4px;
  color: red;
}
.breadcrumb:before {
  color: red;
}

.home-icon {
  display: flex;
}

.groups-container {
  display: flex;
  align-items: center;
  .add-icon {
    display: flex;
    margin-left: 14px;
  }
  .folder-icon {
    margin-right: 14px;
  }
}
.MuiPaper-root.MuiExpansionPanel-root.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  background: transparent;
  box-shadow: none !important;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  min-height: 35px;
}
.MuiExpansionPanelSummary-root {
  margin: 3px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  min-height: 35px !important;
}
.Mui-expanded {
  margin: 3px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  min-height: 35px;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 3px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  min-height: 35px;
}

.group-entry-container {
  cursor: grab;
  display: flex;
  width: 100%;
  .spacer- {
    flex: 1 1 auto;
  }
  .folder-icon {
    cursor: pointer;
    margin-right: 21px;
    display: flex;
    align-items: center;
  }
  .group-entry-text {
    color: #15325f;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2;
  }
  .stats-container {
    display: flex;
  }
}
.makeStyles-content-6 {
  padding: 24px;
  flex-grow: 1;
  background-color: #f9f9f9;
}
.makeStyles-header_text-18 {
  font-size: 12px;
}
.MuiExpansionPanelDetails-root {
  margin: 3px 0px 0px 0px !important;
  display: flex;
  padding: 5px 16px 5px 29px !important;
}
.stats-container > div {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #6d809d;
  margin-right: 38px;
  width: 43px;
}
.stats-container > div > div:first-child {
  margin-right: 8px;
  display: flex;
}
.stats-container > div.status {
  margin-right: 0px;
  margin-left: 58px;
}

.MuiExpansionPanelDetails-root {
  background: #fff;
  margin-bottom: 13px;
  box-shadow: 0px 2px 8px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
}

.MuiExpansionPanelDetails-root.entry-component:hover {
  background: #f5f9fe;
  box-shadow: 0px 2px 8px rgba(153, 153, 153, 0.15);
}

//Actice State
.MuiExpansionPanelDetails-root.entry-component.active {
  background: #e8f1fd;
  /* drop shadow */
  box-shadow: 0px 2px 8px rgba(153, 153, 153, 0.15);
}

.search-view {
  display: flex;
  align-items: center;
}
.groups-container {
  width: 17%;
  .users-container {
    display: flex;
    .user-count {
      height: 35px;
      width: 65px;
      background: #377ae1;
      border-radius: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .count {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        position: relative;
        top: -1px;
      }
    }
  }
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  padding-bottom: 0;
  .text {
    width: 50%;
    margin: 0 auto;
    display: flex;
    span.add-icon {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: rgba(21, 50, 95, 0.87);
  padding: 9px 5px;
  span.icon {
    position: absolute;
    right: 18px;
    visibility: hidden;
  }
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button:hover {
  background: #c7d9f2;
  span.icon {
    visibility: visible;
  }
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.add-user-menu-button.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  background: #377ae1;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 13px 15px;
  margin-top: 10px;
}

.MuiListItem-gutters {
  padding-left: 16px !important;
  padding-right: 60px !important;
}

.user:nth-child(2) {
  position: relative;
  right: -13%;
  z-index: 0;
}
.user:nth-child(1) {
  position: relative;
  right: -20%;
  z-index: 0;
}
.user:nth-child(3) {
  position: relative;
  right: -10px;
  z-index: 0;
}
.user-count {
  position: relative;
  z-index: 1;
}

.spacer {
  flex: 1 1 auto;
}
.space {
  margin-left: 100px;
}
.MuiExpansionPanel-root:before {
  display: none;
}

.groups-container.main {
  width: 100%;
}

.groups-container.main .text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #15325f;
}

#user-menu
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  top: 146px !important;
}

.user img {
  width: 34px;
  height: 34px;
}
.arrow-container {
  display: flex;
  justify-content: center;
  .arrow {
    width: 40px;
    height: 20px;
    background: #fff;
    position: fixed;
    top: 129px;
    z-index: 999999999999999999;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(49% 0%, 0% 100%, 100% 100%);
  }
}

.MuiPaper-elevation8 {
  box-shadow: 0px 2px 8px rgba(153, 153, 153, 0.15) !important;
}

.panel-container {
  display: flex;
  padding-left: 13px;
  .folder-icon {
    margin-right: 15px;
  }
  .text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #15325f;
  }
}
span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1
  .add-icon {
  margin-left: 10px;
  margin-bottom: -5px;
}

//Dialog Styling
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  padding: 5px 24px 24px 24px;
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
  .MuiFormControl-root.MuiTextField-root.group-modal.add-new-group-textfield {
    width: 512px !important;
  }
  .button-container {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.save-button {
      background: #5498ff;
      border-radius: 4px;
      color: #fff;
      span.button-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.0357143em;
        text-transform: uppercase;
      }
    }
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.cancel-button {
      background: transparent;
      box-shadow: unset;
      color: #db4437;
      span.button-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.0357143em;
        text-transform: uppercase;
      }
    }
  }
  .MuiFilledInput-input {
    padding: 30px 12px 10px;
  }

  .MuiFilledInput-root {
    background-color: transparent;
  }
  h2.MuiTypography-root.MuiTypography-h6 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.87);
    margin-left: -20px;
  }
  .group-modal {
    .MuiFilledInput-root.Mui-focused {
      background-color: rgba(199, 217, 242, 0.2);
    }
    .MuiFilledInput-underline:before {
      border-bottom: 1px solid #c7d9f2;
    }
  }
}

nav.MuiTypography-root.MuiBreadcrumbs-root.MuiTypography-body1.MuiTypography-colorTextSecondary
  ol.MuiBreadcrumbs-ol
  li.MuiBreadcrumbs-li
  a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorInherit {
  color: #377ae1;
}

nav.MuiTypography-root.MuiBreadcrumbs-root.MuiTypography-body1.MuiTypography-colorTextSecondary
  ol.MuiBreadcrumbs-ol
  li.MuiBreadcrumbs-li:last-of-type
  a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorInherit {
  color: #15325f;
}

div#search-menu .MuiFormGroup-root {
  outline: none;
}

div#search-menu {
  position: fixed;
  z-index: 1300;
  right: 0px;
  bottom: 0px;
  top: 88px !important;
  left: -149px !important;
}

div#search-menu
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded
  ul.MuiList-root.MuiMenu-list.MuiList-padding
  .MuiFormGroup-root
  label.MuiFormControlLabel-root
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: rgba(21, 50, 95, 0.87);
}

div#search-menu
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded
  ul.MuiList-root.MuiMenu-list.MuiList-padding {
  padding-top: 0;
}

div#search-menu
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded
  ul.MuiList-root.MuiMenu-list.MuiList-padding
  label.MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 27px;
}

form.MuiPaper-root.makeStyles-root-50.MuiPaper-elevation1.MuiPaper-rounded
  .select-div
  .icon:hover {
  cursor: pointer;
}

div#search-menu
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded
  ul.MuiList-root.MuiMenu-list.MuiList-padding
  .MuiFormGroup-root
  label.MuiFormControlLabel-root
  span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-253.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-checked-254.Mui-checked.MuiIconButton-colorPrimary
  span.MuiIconButton-label
  svg.MuiSvgIcon-root {
  color: #377ae1;
}
